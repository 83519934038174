import Vue from 'vue'
import { registerLicense } from '@syncfusion/ej2-base'

export default ({ $config, $axios }) => {
  /**
   * Call the syncfusion licence registration with the supplied ENV variable
   * @param {String} licenceKey - Syncfusion Licence Key
   */
  registerLicense($config.syncfusion)

  /**
   * Call the syncfusion api with file formdata and returns html
   * @param {Array} files - An array of file objects
   * @returns {String} - Html generated from the supplied docx file
   */
  Vue.prototype.$importDocToHtml = async function (files) {
    if (!files || !files.length) {
      return false
    }
    const formData = new FormData()
    formData.append('UploadFiles', files[0])
    try {
      return await $axios.$post('https://services.syncfusion.com/js/production/api/RichTextEditor/ImportFromWord', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  /**
   * This function converts HTML to either a PDF of Docx using the syncFusion api
   * @param {string} input valid HTML to be converted to the document
   * @param {boolean} input should this generate a docx
   * @returns {blob} Blob output to be saved into the document
   */
  Vue.prototype.$richTextToDownload = async function (rtHtml, isDocx) {
    try {
      return await $axios.$post(
        `https://services.syncfusion.com/js/production/api/RichTextEditor/ExportTo${isDocx ? 'Docx' : 'Pdf'}`,
        { html: `<html><head></head><body>${rtHtml}</body></html>` },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        }
      )
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }
}
