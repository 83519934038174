import _ from 'lodash'

export default {
  props: {
    /**
     * Filter options (will override the header filter properties)
     * Array[] {
     *  text: "Group One",
     *  value: "group1",
     *  options: [
     *    {
     *      filter: (tableRow) => return Boolean ,
     *      text: "Filter Option One",
     *      value: "filterOption1"
     *    }
     *  ]
     * }
     */
    /**
     * { page: this.currentPage,
     *  segment: segment: _.get(_filters.find(el => _.has(el,'segment')),'segment',''),
     *  filter: {filterId: name.toLowerCase(), filterName: name, filterOptions: _filters ? _filters : []
     * }
     */
    /**
     * Give this prop to tables on a page which has multiple tables with filters
     *  (so that the filters don't apply to both tables)
     *
     * This can be overriden by a computed/data property with the name "_filterId" (if you don't have access to props, eg within a nuxt page)
     */

    filterId: {
      type: String,
      default: '',
    },
  },
  methods: {
    normalizeObject(obj) {
      return _.mapValues(obj, (value) => {
        if (_.isArray(value) && value.length === 1) {
          // If it's an array with one element, convert to that single element
          return value[0]
        }
        return value
      })
    },
    patchCurrentFilters(filterOptions, segment, filterName, filterId) {},
    parseURL() {
      // Watch for the URL for /tasks?id=_id, and open a dialog for the provided task
      let params = this.$route.query
      if (_.has(params, 'segmentFilter')) {
        this.activeSegment = { value: _.get(params, 'segmentFilter') }
      } else {
        this.activeSegment = null
      }
      //now remove these and loop over the rest and build the filter options
      delete params?.id
      delete params?.segmentFilter

      let filterOptions = []
      Object.entries(params).forEach(([k, v]) => {
        if (Array.isArray(v)) {
          v.forEach((el) => {
            filterOptions.push({ group: k, value: el })
          })
        } else {
          filterOptions.push({ group: k, value: v })
        }
      })
      this.filters = filterOptions
    },
    useStickyVariables(filters) {
      if (_.isNil(filters) || filters.length === 0) return false
      //myTask Sticky
      if (this.activeFilter?.filter?.filterId == 'myTasks') {
        return _.map(filters, 'value').includes(this.$auth.$state.user.sub)
      }
      return _.get(this.activeFilter, 'filter.sticky', false)
    },
    applySavedFilter(filterOptions, segment, filterName, filterId, sticky = false) {
      this.$store.commit('auxiliary/filters/setActiveFilter', {
        page: this.currentPage,
        segment: segment,
        filter: { filterId: filterId, filterName: filterName, filterOptions: filterOptions, sticky: sticky },
      })
      // Set query
      let segmentFilter = segment ? { segmentFilter: segment.value } : {}

      const newQuery = Object.assign(
        segmentFilter,
        _.mapValues(_.groupBy(filterOptions, 'group'), (group) => group.map((el) => el.value))
      )
      if (!_.isEqual(this.normalizeObject(this.$route.query), this.normalizeObject(newQuery))) {
        this.$router.replace({ query: newQuery })
      }
    },
    applyUrlFilter(filters, filterId = 'custom', filterName = 'Custom') {
      if (!Array.isArray(filters)) filters = [filters]

      const groupedData = _.groupBy(filters, 'name')

      const transformedData = _.mapValues(groupedData, (group) => {
        if (group.length === 1) {
          return group[0].value
        } else {
          return _.map(group, 'value')
        }
      })
      if (!_.isEqual(this.normalizeObject(this.$route.query), this.normalizeObject(transformedData))) {
        this.$router.replace({ query: transformedData })
      }

      let segment = _.get(transformedData, 'segmentFilter', null) ? { value: _.get(transformedData, 'segmentFilter', null) } : null

      if (_.has(transformedData, 'segmentFilter')) delete transformedData.segmentFilter
      if (_.has(transformedData, 'id')) delete transformedData.id
      this.$store.commit('auxiliary/filters/setActiveFilter', {
        page: this.currentPage,
        segment: segment,
        filter: {
          filterId,
          filterName,
          filterOptions: Object.entries(transformedData).map(([k, v]) => {
            return { group: k, value: v }
          }),
        },
      })
    },
  },
  computed: {
    savedFilterNames() {
      const allFilters = this.$store.getters['auxiliary/filters/getByPage'](this.currentPage)
      if (!allFilters || !Array.isArray(allFilters.filters)) return []

      return allFilters.filters.map((filter) => filter.filterName) || []
    },
    currentPage() {
      const page = this.$store.getters['auxiliary/filters/getFilterPage']
      return this._filterId ? `${page}_${this._filterId}` : this.filterId ? `${page}_${this.filterId}` : page
    },
    activeSegment: {
      get() {
        return _.get(this.activeFilter, 'segment', null)
      },
      set(_segment) {
        //Remove segment fitlers that are applied when changing segments
        let newFilters = _.get(this.activeFilter, 'filter.filterOptions', []).filter((filter) => {
          //A little hacky but this should include a list of all segmentable options
          //Will upgrade this when its used more commonly to get passed its segments
          return !['entityGroupId', 'dueDate', 'teams'].includes(filter.group)
        })
        this.$store.commit('auxiliary/filters/setActiveFilter', {
          page: this.currentPage,
          segment: _segment,
          filter: { filterId: this.activeFilter?.filter.sticky ? this.activeFilter.filter.filterId : 'custom', filterName: this.activeFilter?.filter.sticky ? this.activeFilter.filter.filterName : 'Custom', filterOptions: newFilters, sticky: _.get(this.activeFilter, 'filter.sticky', false) },
        })
        // Set query
        const newQuery = Object.assign(_.cloneDeep(this.$route.query), { segmentFilter: _segment?.value })
        if (!_.isEqual(this.normalizeObject(this.$route.query), this.normalizeObject(newQuery))) {
          this.$router.replace({ query: newQuery })
        }
      },
    },
    activeFilter() {
      return this.$store.getters['auxiliary/filters/getActiveFilterByPage'](this.currentPage)
    },
    filters: {
      get() {
        return _.get(this.activeFilter, 'filter.filterOptions', [])
      },
      set(_filters) {
        this.$store.commit('auxiliary/filters/setActiveFilter', {
          page: this.currentPage,
          segment: this.activeSegment,
          filter: { filterId: this.useStickyVariables(_filters) ? this.activeFilter.filter.filterId : 'custom', filterName: this.useStickyVariables(_filters) ? this.activeFilter.filter.filterName : 'Custom', filterOptions: _filters, sticky: _.get(this.activeFilter, 'filter.sticky', false) },
        })
        // Set query
        let routeQuery = Object.assign(
          _.cloneDeep(this.$route.query),
          _.mapValues(_.groupBy(_filters, 'group'), (group) => {
            if (group.length === 1) {
              return group[0].value
            } else {
              return _.map(group, 'value')
            }
          })
        )
        // Only navigate if query is going to change
        if (!_.isEqual(this.normalizeObject(this.$route.query), this.normalizeObject(routeQuery))) {
          this.$router.replace({ query: routeQuery })
        }
      },
    },
    groupedFilters() {
      if (this.filters.length === 0) return null
      let groupedData = {}
      Object.entries(_.groupBy(this.filters, 'group')).forEach(([key, value]) => {
        if (key != 'undefined') {
          if (!Array.isArray(value)) {
            groupedData[key] = value.map((el) => el.value)
          } else {
            //gotta unpack values
            groupedData[key] = _.flatMapDeep(value)
          }
        }
      })
      return groupedData
    },
  },
}
