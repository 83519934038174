export const state = () => ({
  allItems: [],
})

export const getters = {
  getItems(state) {
    return state.allItems
  },
}

export const mutations = {}

export const actions = {
  async getAll({ state, commit, rootGetters, getters }) {
    const result = await this.$axios.$get(`/api/v1/eventLog`)
    commit('application/storeUtils/setItem', { localState: state, item: 'allItems', value: result }, { root: true })
  },
  async getRecent({ state }, { groupId, collection }) {
    return await this.$axios.$get(`/api/v1/eventLog/recent`, {
      params: {
        groupId: groupId,
        collection: collection,
      },
    })
  },
  async findRange({ commit }, body) {
    if (!body.start) return []
    return _.defaultTo(await this.$axios.$post('/api/v1/eventLog/findRange', body), [])
  },
}
