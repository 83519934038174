
import configMixin from '@/mixins/configMixin.js'

export default {
  mixins: [configMixin],
  props: {
    /**
     * The array (multi selections) or object (single select) value(s)
     */
    value: {
      type: [Array, Object],
      default: null,
    },
    /**
     * The object to be displayed
     */
    item: {
      type: Object,
      default: null,
    },
    /**
     * The ID of the currently selected object, used to modify styling if the selectedId is the same as the Id
     */
    selectedId: {
      type: String,
      default: null,
    },
    /**
     * The module name used to show the icon and retrieve the object name
     */
    module: {
      type: String,
      default: null,
    },
    /**
     * Display checkboxes to select multiple objects
     */
    multiSelect: {
      type: Boolean,
      default: false,
    },
    /**
     * Displays radio buttons to select a single object
     */
    singleSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: false,
    }
  },
  computed: {
    id() {
      return _.get(this.item, 'id')
    },
    app() {
      return this.$store.getters['application/appData/getAppValueFromModule'](this.module)
    },
    isObjectSelected() {
      return this.id && this.selectedId && this.id === this.selectedId ? true : false
    },
    isActionSelected() {
      if (this.multiSelect && this.value.findIndex((el) => _.get(el, 'id') === this.id) >= 0) return true
      else if (this.singleSelect && _.get(this.value, 'id') === this.id) return true
      return false
    },
    objectName() {
      if (this.module === 'users') return this.item?.name ?? 'Unnamed'
      return this.$utils.getDisplayName(this.item.id)
    },
    objectDescription() {
      if (this.module === 'users') return this.item.roles.map((m) => this.$utils.capitaliseWords(m.name)).join(', ')
      return this.$utils.getSubtitle(this.item.id)
    },
    newTabUrl() {
      // Different URLs for certain modules
      let url = `/${this.module}${this.item?.isTemplate ? '/templates' : ''}`
      if (this.module === 'tasks') {
        return `${url}?id=${this.id}`
      }
      return `${url}/${this.id}`
    },
    groupName() {
      const id = this.item?.entityGroupId || this.item?.clientGroupId
      if (!id) return
      const groupName = this.$store.getters['modules/entityGroups/getLabel'](id)
      return groupName && groupName !== 'Unnamed' ? groupName : null
    },
  },
  methods: {
    updateMulti(val) {
      // Add or remove ID from the selected array and then emit new value
      let clone = _.cloneDeep(this.value)
      let foundIndex = clone.findIndex((el) => _.get(el, 'id') === this.id)
      if (val === null && foundIndex >= 0) clone.splice(foundIndex, 1)
      else if (val && foundIndex === -1) clone.push(this.item)
      this.$emit('input', clone)
    },
    checkRadio() {
      // Reset selection if clicking a selected radio button
      if (_.get(this.value, 'id') === this.id) this.$emit('input', null)
      else this.$emit('input', this.item)
    },
  },
}
