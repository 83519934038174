import Vue from 'vue'

export const state = () => ({
  pending: false,
  folders: [],
  foldersPending: null,
  documents: [],
  documentsPending: null,
  objects: {},
  objectsPending: null,
  toDownload: null,
  uploadPreview: null,
  deletePending: null,
  fileLookupCache: {},
  // should be empty string or the entityGroup to show in the document viewer
  rootPrefix: String(sessionStorage.getItem('selectedEntityGroup')).startsWith('entityGroups-') ? sessionStorage.getItem('selectedEntityGroup') : '',
  // The path prefix used for sorting into subfolders
  prefix: null,
  objectsPendingForUpload: [],
})

export const getters = {
  getRootPrefix(state) {
    return state.rootPrefix
  },
  getById: (state) => (id) => {
    if (!Array.isArray(state.documents)) return null

    const found = state.documents
      .concat(state.objectsPendingForUpload)
      .concat(Object.values(state.fileLookupCache))
      .find((val) => val.id === id)

    return found ? { ...found, name: found.fileName } : null
  },
  getByPath: (state) => (path) => {
    if (!Array.isArray(state.documents)) return null
    const found = state.documents
      .concat(state.objectsPendingForUpload)
      .concat(Object.values(state.fileLookupCache))
      .find((val) => val.path === path)
    return found ? found : null
  },
  getMultipleByIds: (state) => (ids) => {
    if (!Array.isArray(state.documents)) return []

    if (!Array.isArray(ids)) ids = [ids]

    const uniq = _.uniqBy(state.documents.concat(state.objectsPendingForUpload).concat(Object.values(state.fileLookupCache)), 'id')

    return uniq.filter((val) => ids.includes(val.id)).map((val) => ({ ...val, name: val.fileName }))
  },
}

export const mutations = {
  stageUploads(state, data) {
    state.objectsPendingForUpload = data
  },
  clearStagedUploads(state) {
    state.objectsPendingForUpload.splice(0, state.objectsPendingForUpload.length)
  },
  setPrefix(state, data) {
    state.prefix = data
  },
  setRootPrefix(state, data) {
    state.rootPrefix = data
  },
  updateFileLookupCache(state, data) {
    data.forEach((document) => {
      Vue.set(state.fileLookupCache, document.id, document)
    })
  },
  setSystemPrefix(state, data) {
    state.prefix = '.System/' + data
  },
  setDocuments: (state, data) => {
    state.documents = data
  },
  setFolders: (state, data) => {
    state.folders = data
  },
  putDocuments: (state, data) => {
    if (typeof data !== 'object' || !('id' in data)) return

    const foundDocument = state.documents.find((doc) => doc.id === data.id)

    if (!foundDocument) {
      // Not found - add
      state.documents.push(data)
    } else {
      // If the document already exists in the array replace it
      state.documents = state.documents.map((doc) => {
        if (doc.id === data.id) {
          return _.cloneDeep(data)
        } else {
          return _.cloneDeep(doc)
        }
      })
    }
  },
  removeObject: (state, fileName) => {
    const objects = _.cloneDeep(state.objects)
    if (!objects?._documents) return
    if (!_.has(objects?._documents, fileName)) return

    state.objects = {
      ...objects,
      _documents: _.omit(objects?._documents, fileName),
    }
  },
  deleteDocumentIds: (state, ids) => {
    if (!Array.isArray(ids)) return
    state.documents = state.documents.filter((doc) => !ids.includes(doc.id))
  },
  setToDownload: (state, data) => {
    state.toDownload = data
  },
  setObjects: (state, data) => {
    state.objects = data
  },
  setUploadPreview: (state, data) => {
    state.uploadPreview = data
  },
  setPending(state, { key, payload }) {
    state[key] = payload
  },
  switchEntityGroup(state) {
    this.commit('modules/fileManager/refreshDocumentMap', null, { root: true })
    this.commit('modules/fileManager/setSearchTerms', '', { root: true })
    this.commit('modules/fileManager/setCurrentPath', '', { root: true })

    this.commit('auxiliary/documents/setObjects', {}, { root: true })
    this.commit('auxiliary/documents/setFolders', {}, { root: true })
    this.commit('auxiliary/documents/setDocuments', [], { root: true })
  },
}

export const actions = {
  /*
   * S3 actions
   */
  async getObjects({ state, commit, dispatch, rootState }, payload) {
    if (state.objectsPending !== true) commit('setPending', { key: 'objectsPending', payload: true })

    await this.$axios
      .get('/api/v1/document/s3', { params: { folder: payload || '' } })
      .then((res) => {
        if (res.data) {
          commit('setObjects', res.data)
          if (state.objectsPending) commit('setPending', { key: 'objectsPending', payload: false })
        }
      })
      .catch((err) => {
        if (state.objectsPending) commit('setPending', { key: 'objectsPending', payload: false })
      })
  },
  async deleteIfEmpty({ state, commit, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete('/api/v1/document/s3', { params: { folderPath: payload || '' } })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async forceDelete({ state, commit, dispatch, rootState }, payload) {
    if (state.deletePending !== true) commit('setPending', { key: 'deletePending', payload: true })

    return new Promise((resolve, reject) => {
      this.$axios
        .delete('/api/v1/document/s3/force', { params: { folderPath: payload || '' } })
        .then(async (res) => {
          commit('setPending', { key: 'deletePending', payload: false })
          await dispatch('getFolders')
          resolve(res)
        })
        .catch((err) => {
          commit('setPending', { key: 'deletePending', payload: false })
          reject(err)
        })
    })
  },
  async createFolder({ state, commit, dispatch }, payload) {
    if (!/^[0-9a-zA-Z\-_() ?$&@=+]+\/$/.test(payload?.name)) return { status: 400, data: 'Folder names must only contain letters, numbers, spaces, and  - _ () ? $ & @ = +' }
    if (state.foldersPending !== true) commit('setPending', { key: 'foldersPending', payload: true })

    return new Promise((resolve, reject) => {
      this.$axios
        .post('/api/v1/document/s3/createFolder', payload)
        .then(async (res) => {
          if (res.data) {
            commit('setFolders', res.data)
            commit('setPending', { key: 'foldersPending', payload: false })
            await dispatch('getFolders')
          }
          resolve(res)
        })
        .catch((err) => {
          commit('setPending', { key: 'foldersPending', payload: false })
          resolve(err?.response)
        })
    })
  },
  /**
   * Gets the folder structure of the client group's s3 bucket
   * @param {*} param0
   * @param {*} payload
   */
  async getFolders({ state, commit, dispatch }, payload) {
    if (state.foldersPending) return

    commit('setPending', { key: 'foldersPending', payload: true })
    await this.$axios
      .get('/api/v1/document/structure')
      .then((res) => {
        if (res.data) {
          commit('setFolders', res.data)
          commit('setPending', { key: 'foldersPending', payload: false })
        }
      })
      .catch((err) => {
        commit('setPending', { key: 'foldersPending', payload: false })
      })
  },
  /*
   * Document actions
   */
  async fetchDocumentSaveToCache({ state, commit }, payload) {
    const missingIds = _.difference(payload, Object.keys(state.fileLookupCache))
    if (!missingIds.length) return
    commit('updateFileLookupCache', await this.$axios.$get(`/api/v1/document?ids=${missingIds.join(',')}`))
    return _.at(state.fileLookupCache, payload)
  },
  async getDocumentsByEntityGroup({ state, commit, dispatch, rootState }) {
    if (state.documentsPending !== true) commit('setPending', { key: 'documentsPending', payload: true })
    commit('setDocuments', await this.$axios.$get('/api/v1/document'))
    if (state.documentsPending) commit('setPending', { key: 'documentsPending', payload: false })
  },
  async getDocumentById({ state }, id) {
    return await this.$axios.get('/api/v1/document/' + id).then((res) => {
      return res
    })
  },
  async uploadPendingDocuments({ state, commit, dispatch }) {
    const failedIds = []

    const promises = state.objectsPendingForUpload.map(async (obj) => {
      try {
        const data = await this.$axios.$post('/api/v1/document', obj)
        commit('putDocuments', data)
      } catch (error) {
        console.warn('uploadPendingDocuments failed ', error)
        failedIds.push(obj.id)
      }
    })
    commit('setPending', { key: 'pending', payload: true })
    await Promise.all(promises)
    commit('setPending', { key: 'pending', payload: false })
    commit('clearStagedUploads', [])
    return failedIds
  },
  async upload({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      commit('setPending', { key: 'pending', payload: true })
      this.$axios
        .post('/api/v1/document', state.prefix ? { ...payload, pathPrefix: state.prefix } : payload)
        .then(async (res) => {
          const newDocument = res.data?.document
          const newObjects = res.data?.objects

          if (!newDocument || !newObjects) {
            commit('setPending', { key: 'pending', payload: false })
            return resolve(res)
          }

          // Add to documents
          const currentPath = _.cloneDeep(rootState?.modules?.fileManager?.currentPath)
          commit('putDocuments', newDocument)
          commit('setObjects', newObjects)
          commit('modules/fileManager/setDocumentMap', { path: currentPath, value: newObjects }, { root: true })

          // Update file manager
          Object.entries(newObjects).forEach(([k, v]) => {
            Object.entries(v).forEach(([key, val]) => {
              const path = currentPath + key

              commit('modules/fileManager/setDocumentMap', { path, value: val }, { root: true })
            })
          })

          commit('setPending', { key: 'pending', payload: false })
          resolve(newDocument)
        })
        .catch((err) => {
          commit('application/snack/set', { type: 'error', message: err?.response?.data }, { root: true })
          commit('setPending', { key: 'pending', payload: false })
          reject(err)
        })
    })
  },
  async transfer({ commit, rootState }, { id, payload }) {
    return new Promise((resolve, reject) => {
      commit('setPending', { key: 'pending', payload: true })
      this.$axios
        .put('/api/v1/document/transfer/' + id, payload)
        .then((res) => {
          const newDocument = res.data?.document
          const newObjects = res.data?.objects
          const currentPath = _.cloneDeep(rootState?.modules?.fileManager?.currentPath)

          commit('putDocuments', newDocument)
          commit('setObjects', newObjects)
          commit('modules/fileManager/setDocumentMap', { path: currentPath, value: newObjects }, { root: true })

          // Update file manager
          Object.entries(newObjects).forEach(([k, v]) => {
            Object.entries(v).forEach(([key, val]) => {
              const path = currentPath + key

              commit('modules/fileManager/setDocumentMap', { path, value: val }, { root: true })
            })
          })
          commit('setPending', { key: 'pending', payload: false })
          resolve(res)
        })
        .catch((e) => {
          commit('application/snack/set', { type: 'error', message: e?.response?.data }, { root: true })
          commit('setPending', { key: 'pending', payload: false })
          reject(e?.response?.data)
        })
    })
  },
  async copy({ state, commit, rootState }, { id, payload }) {
    return new Promise((resolve, reject) => {
      commit('setPending', { key: 'pending', payload: true })
      this.$axios
        .post('/api/v1/document/copy/' + id, payload)
        .then((res) => {
          const newDocument = res.data?.document
          const newObjects = res.data?.objects

          commit('putDocuments', newDocument)
          commit('setObjects', newObjects)

          const currentPath = _.cloneDeep(rootState?.modules?.fileManager?.currentPath)
          commit('modules/fileManager/setDocumentMap', { path: currentPath, value: newObjects }, { root: true })
          Object.entries(newObjects).forEach(([k, v]) => {
            Object.entries(v).forEach(([key, val]) => {
              const path = currentPath + key
              commit('modules/fileManager/setDocumentMap', { path, value: val }, { root: true })
            })
          })

          commit('setPending', { key: 'pending', payload: false })
          resolve(res)
        })
        .catch((e) => {
          commit('application/snack/set', { type: 'error', message: e?.response?.data }, { root: true })
          commit('setPending', { key: 'pending', payload: false })
          reject(e?.response?.data)
        })
    })
  },
  async search({ state, commit, dispatch, rootState }, payload) {
    if (state.documentsPending !== true) commit('setPending', { key: 'documentsPending', payload: true })

    await this.$axios
      .get('/api/v1/documents/search', { params: { fileName: payload } })
      .then((res) => {
        if (res.data) {
          commit('setDocuments', res.data)
          if (state.documentsPending) commit('setPending', { key: 'documentsPending', payload: false })
        }
      })
      .catch((err) => {
        if (state.documentsPending) commit('setPending', { key: 'documentsPending', payload: false })
      })
  },
  async getDocumentData({ state, commit, dispatch }, { path, absolute }) {
    return (await this.$axios.get('/api/v1/document/download', { params: { path, absolute }, responseType: 'blob' }))?.data
  },
  async downloadDocument({ state, commit, dispatch }, { path, absolute }) {
    commit('setPending', { key: 'pending', payload: true })
    await this.$axios.get('/api/v1/document/download', { params: { path, absolute }, responseType: 'blob' }).then((res) => {
      if (res.data) {
        commit('setToDownload', res)
      }
    })
    commit('setPending', { key: 'pending', payload: false })
  },
  async documentPreview({ state, commit, dispatch }, id) {
    return await this.$axios.get('/api/v1/document/preview', { params: { id: id }, responseType: 'blob' })
  },
  async uploadPreview({ state, commit, dispatch }, payload) {
    await this.$axios.post('/api/v1/documents/upload_preview', payload).then((res) => {
      if (res.data) {
        commit('setUploadPreview', res.data)
      }
    })
  },
  // If any ids were not found it will respond with an array of the ids not deleted
  async deleteByIds({ state, commit, dispatch }, payload) {
    if (!Array.isArray(payload)) payload = [payload]
    if (state.deletePending !== true) commit('setPending', { key: 'deletePending', payload: true })

    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`/api/v1/document?ids=${payload.join(',')}`)
        .then((res) => {
          if (state.deletePending) commit('setPending', { key: 'deletePending', payload: false })
          if (Array.isArray(res.data)) {
            commit('deleteDocumentIds', _.difference(payload, res.data))
          } else {
            commit('deleteDocumentIds', payload)
          }
          resolve(res)
        })
        .catch((err) => {
          if (state.deletePending) commit('setPending', { key: 'deletePending', payload: false })
          reject()
        })
    })
  },
  // Given an ID from document library or the S3 document convert it into base64
  async getDocumentBase64({ dispatch }, documentId) {
    let document = documentId
    if (typeof documentId === 'string') {
      document = (await dispatch('getDocumentById', documentId)).data
    }
    const documentBlob = await dispatch('getDocumentData', { path: document.path, absolute: false })
    const documentData = await new Response(documentBlob).arrayBuffer()
    return btoa(new Uint8Array(documentData).reduce((data, byte) => data + String.fromCharCode(byte), ''))
  },
  async update({ commit, rootState }, { id, payload }) {
    return new Promise((resolve, reject) => {
      commit('setPending', { key: 'pending', payload: true })
      this.$axios
        .put(`/api/v1/document/update/${id}`, payload)
        .then((res) => {
          const newDocument = res.data?.document
          const newObjects = res.data?.objects

          if (!newDocument || !newObjects) {
            commit('setPending', { key: 'pending', payload: false })
            return resolve(res)
          }

          commit('putDocuments', newDocument)
          commit('setObjects', newObjects)

          const currentPath = _.cloneDeep(rootState?.modules?.fileManager?.currentPath)
          commit('modules/fileManager/setDocumentMap', { path: currentPath, value: newObjects }, { root: true })
          Object.entries(newObjects).forEach(([k, v]) => {
            Object.entries(v).forEach(([key, val]) => {
              const path = currentPath + key
              commit('modules/fileManager/setDocumentMap', { path, value: val }, { root: true })
            })
          })

          commit('setPending', { key: 'pending', payload: false })
          resolve(res)
        })
        .catch((err) => {
          commit('application/snack/set', { type: 'error', message: _.get(err, 'response.data', 'There was a problem modifying that document.') }, { root: true })
          commit('setPending', { key: 'pending', payload: false })
          resolve(err)
        })
    })
  },
  /**
   * @deprecated
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  async rename({ state, commit, dispatch }, { id, payload }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .put('/api/v1/document/rename/' + id, payload)
        .then((res) => {
          commit('putDocuments', res.data)
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}
