import CrudStore from '~/store/modules/common/crudStore'
import Vue from 'vue'

var crud = new CrudStore('fileNotes', null, false)

export const state = () => ({
  ...crud.state,
  blankFileNote: null,
  noteTemplate: {},
  noSidebar: true,
  topBarButtons: [
    { name: 'File Notes', link: '/fileNotes' },
    { name: 'Template File Notes', link: '/fileNotes/templates' },
  ],
})

export const getters = {
  ...crud.getters,
  getFilterOptions(state, getters) {
    const defaultFields = ['type', 'status']

    return defaultFields.map((key) => ({
      text: $nuxt.$utils.capitaliseWords(key),
      value: key,
      mask: key == 'type' ? 'configurationHub' : key,
      options: _.uniq(getters.getItems.map((v) => _.get(v, key, '')))
        .filter((v) => !!v)
        .map((v) => ({ text: v, value: v, filter: (val) => val[key] === v })),
    }))
  },
  // override
  getItems(state, getters, rootState) {
    let items = getters['getAllItems'].filter((el) => !el.deleted || el.deleted === 'ARCHIVED')
    return _.sortBy(items, (el) => $nuxt.$utils.getDisplayName(el?.id)?.toLowerCase()) // Sort the items by default
  },
  getLabel: (state, getters, rootState, rootGetters) => (id) => {
    if (!id) return ''
    const cachedObj = typeof id === 'object' ? id : rootGetters[`application/globalSearch/getCacheObject`](id) // Check cache for ID

    const fileNote = cachedObj ? cachedObj : getters['getAllItems'].find((el) => el.id === id) // If not found in cache, try find it
    if (!fileNote) return ''

    return fileNote.subject ? fileNote.subject : 'File Note'
  },
  getSubtitle: (state, getters, rootState, rootGetters) => (id) => {
    if (!id) return ''
    const cachedObj = rootGetters[`application/globalSearch/getCacheObject`](id) // Check cache for ID
    const fileNote = cachedObj ? cachedObj : getters['getAllItems'].find((el) => el.id === id) // If not found in cache, try find it
    if (!fileNote) return ''

    const noteTags = rootGetters['modules/configurationHub/getTags']('fileNoteTags')
    const types = rootGetters['modules/configurationHub/getItems']('fileNote.types')
    const advisors = rootState.auxiliary.user.org.users.map((item) => {
      return { name: item.name, id: item.user_id }
    })

    // Generate text for fields that should display
    let displayText = []
    if (fileNote.type) displayText.push(types.find((el) => el.id === fileNote.type)?.name)
    if (_.get(fileNote, 'tags', []).length > 0) {
      // Get used tags
      displayText.push(
        $nuxt.$utils.concatStrings(
          ', ',
          noteTags.filter((el) => fileNote.tags.includes(el.id)).map((el) => el.name)
        )
      )
    }
    if (_.get(fileNote, 'clients', []).length > 0) {
      // Get used client names
      displayText.push($nuxt.$utils.concatStrings(', ', $nuxt.$utils.getMultipleDisplayNames(fileNote.clients)))
    }
    if (_.get(fileNote, 'advisors', []).length > 0) {
      // Get used advisors
      displayText.push(
        $nuxt.$utils.concatStrings(
          ', ',
          advisors.filter((el) => fileNote.advisors.includes(el.id)).map((el) => el.name)
        )
      )
    }
    return $nuxt.$utils.concatStrings(' — ', displayText)
  },
}

export const mutations = {
  ...crud.mutations,
  setNoteTemplate: (state, objTemplate) => {
    state.noteTemplate = objTemplate
  },
}

export const actions = {
  ...crud.actions,
  async patchStatus({ commit }, { id, status }) {
    if (!status) return
    commit('updateField', { id: id, field: 'status', value: status })
    const updatedObj = await this.$axios.$patch(`/api/v1/fileNotes/status/${id}`, null, {
      params: {
        status: status,
      },
    })
    if (updatedObj) commit('updateField', { id: id, field: 'locked', value: updatedObj.locked })
  },
  async generateSFDT({ state, commit, dispatch, rootState }, file) {
    let formData = new FormData()
    const fileSize = _.get(file, '[0].size')
    if (fileSize == 0) {
      commit('application/snack/set', { type: 'error', message: `The imported file is empty.` }, { root: true })
      return
    }
    if (!_.isNil(file)) {
      formData.append('file', file[0])
    }
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/api/v1/fileNotes/generateSFDT`, formData, { headers: { 'Content-Type': undefined } })
        .then((res) => {
          commit('application/storeUtils/setItem', { localState: state, item: 'pendingCreate', value: res.status }, { root: true })
          setTimeout(() => {
            commit('resetPending')
          }, 2000)
          resolve(res?.data)
        })
        .catch((err) => {
          commit('application/storeUtils/setItem', { localState: state, item: 'pendingCreate', value: false }, { root: true })
          reject(err)
        })
    })
  },
  async generateHTML({ state, commit, dispatch, rootState }, file) {
    let formData = new FormData()
    const fileSize = _.get(file, '[0].size')
    if (fileSize == 0) {
      commit('application/snack/set', { type: 'error', message: `The imported file is empty.` }, { root: true })
      return
    }
    if (!_.isNil(file)) {
      formData.append('file', file[0])
    }
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/api/v1/fileNotes/generateHTML`, formData, { headers: { 'Content-Type': undefined } })
        .then((res) => {
          commit('application/storeUtils/setItem', { localState: state, item: 'pendingCreate', value: res.status }, { root: true })
          setTimeout(() => {
            commit('resetPending')
          }, 2000)
          resolve(res?.data)
        })
        .catch((err) => {
          commit('application/storeUtils/setItem', { localState: state, item: 'pendingCreate', value: false }, { root: true })
          reject(err)
        })
    })
  },
  async saveNew({ state, commit, dispatch, rootState }, newObj) {
    let formData = new FormData()
    const fileSize = _.get(newObj, 'file[0].size')

    if (_.get(newObj, 'file', false)) {
      newObj.note = await $nuxt.$importDocToHtml(newObj.file)
    }

    if (!_.isNil(newObj.file)) {
      if (fileSize > 0) formData.append('file', newObj.file[0])
      delete newObj.file
    }
    if (rootState?.modules?.entityGroups?.selectedEntityGroup?.id && !newObj.isTemplate && _.isEmpty(newObj.entityGroupId)) {
      newObj.entityGroupId = rootState?.modules?.entityGroups?.selectedEntityGroup?.id
    }

    formData.append('fileNote', new Blob([JSON.stringify(newObj, false)], { type: 'application/json' }))
    return new Promise((resolve, reject) => {
      commit('application/storeUtils/setItem', { localState: state, item: 'newEdit', value: null }, { root: true })
      commit('application/storeUtils/setItem', { localState: state, item: 'pendingCreate', value: true }, { root: true })

      this.$axios
        .post(`/api/v1/fileNotes`, formData, { headers: { 'Content-Type': undefined } })
        .then((res) => {
          commit('application/storeUtils/setItem', { localState: state, item: 'pendingCreate', value: res.status }, { root: true })
          setTimeout(() => {
            commit('resetPending')
          }, 2000)

          // Handle object response
          const isObjectResponse = typeof res.data === 'object'
          const newId = isObjectResponse ? res.data?.id : res.data
          const newObject = isObjectResponse ? res.data : { ...newObj, id: newId, entityGroupId: rootState?.modules?.entityGroups?.selectedEntityGroup?.id }

          // Add object to this store
          commit('addObject', newObject)

          resolve(newId)
        })
        .catch((err) => {
          commit('application/storeUtils/setItem', { localState: state, item: 'pendingCreate', value: false }, { root: true })
          reject(err)
        })
    })
  },
  async getNoteTemplate({ state, commit }) {
    let note
    if (state.noteTemplate != null) {
      note = Vue.$_.cloneDeep(state.noteTemplate)
      note.id = this.$uuid()
      return note
    }
    let noteTemplate = (await this.$axios.get(`/api/v1/fileNotes/new/note`)).data
    commit('setNoteTemplate', noteTemplate)
    note = Vue.$_.cloneDeep(noteTemplate)
    note.id = this.$uuid()
    return note
  },
}
