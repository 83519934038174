import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/application/appData.js'), 'application/appData.js')
  resolveStoreModules(require('../store/application/devContent.js'), 'application/devContent.js')
  resolveStoreModules(require('../store/application/eventLog.js'), 'application/eventLog.js')
  resolveStoreModules(require('../store/application/globalSearch.js'), 'application/globalSearch.js')
  resolveStoreModules(require('../store/application/loading.js'), 'application/loading.js')
  resolveStoreModules(require('../store/application/notifications.js'), 'application/notifications.js')
  resolveStoreModules(require('../store/application/snack.js'), 'application/snack.js')
  resolveStoreModules(require('../store/application/storeUtils.js'), 'application/storeUtils.js')
  resolveStoreModules(require('../store/application/webStomp.js'), 'application/webStomp.js')
  resolveStoreModules(require('../store/auxiliary/documents.js'), 'auxiliary/documents.js')
  resolveStoreModules(require('../store/auxiliary/externalData.js'), 'auxiliary/externalData.js')
  resolveStoreModules(require('../store/auxiliary/filters.js'), 'auxiliary/filters.js')
  resolveStoreModules(require('../store/auxiliary/pendo.js'), 'auxiliary/pendo.js')
  resolveStoreModules(require('../store/auxiliary/stripe.js'), 'auxiliary/stripe.js')
  resolveStoreModules(require('../store/auxiliary/user.js'), 'auxiliary/user.js')
  resolveStoreModules(require('../store/integrations/google.js'), 'integrations/google.js')
  resolveStoreModules(require('../store/integrations/integrationEmails.js'), 'integrations/integrationEmails.js')
  resolveStoreModules(require('../store/integrations/microsoft.js'), 'integrations/microsoft.js')
  resolveStoreModules(require('../store/integrations/xero.js'), 'integrations/xero.js')
  resolveStoreModules(require('../store/modules/activities.js'), 'modules/activities.js')
  resolveStoreModules(require('../store/modules/assets.js'), 'modules/assets.js')
  resolveStoreModules(require('../store/modules/clientPortal.js'), 'modules/clientPortal.js')
  resolveStoreModules(require('../store/modules/configurationHub.js'), 'modules/configurationHub.js')
  resolveStoreModules(require('../store/modules/emails.js'), 'modules/emails.js')
  resolveStoreModules(require('../store/modules/employment.js'), 'modules/employment.js')
  resolveStoreModules(require('../store/modules/entities.js'), 'modules/entities.js')
  resolveStoreModules(require('../store/modules/entityGroups.js'), 'modules/entityGroups.js')
  resolveStoreModules(require('../store/modules/fileManager.js'), 'modules/fileManager.js')
  resolveStoreModules(require('../store/modules/fileNotes.js'), 'modules/fileNotes.js')
  resolveStoreModules(require('../store/modules/goalsEventsMilestones.js'), 'modules/goalsEventsMilestones.js')
  resolveStoreModules(require('../store/modules/governmentBenefits.js'), 'modules/governmentBenefits.js')
  resolveStoreModules(require('../store/modules/incomeExpenses.js'), 'modules/incomeExpenses.js')
  resolveStoreModules(require('../store/modules/insurances.js'), 'modules/insurances.js')
  resolveStoreModules(require('../store/modules/invoices.js'), 'modules/invoices.js')
  resolveStoreModules(require('../store/modules/kanbans.js'), 'modules/kanbans.js')
  resolveStoreModules(require('../store/modules/liabilities.js'), 'modules/liabilities.js')
  resolveStoreModules(require('../store/modules/professionals.js'), 'modules/professionals.js')
  resolveStoreModules(require('../store/modules/requests.js'), 'modules/requests.js')
  resolveStoreModules(require('../store/modules/sharedFolders.js'), 'modules/sharedFolders.js')
  resolveStoreModules(require('../store/modules/superannuations.js'), 'modules/superannuations.js')
  resolveStoreModules(require('../store/modules/tasks.js'), 'modules/tasks.js')
  resolveStoreModules(require('../store/modules/workflows.js'), 'modules/workflows.js')
  resolveStoreModules(require('../store/modules/adviceBuilder/adviceTemplates.js'), 'modules/adviceBuilder/adviceTemplates.js')
  resolveStoreModules(require('../store/modules/adviceBuilder/insuranceResearch.js'), 'modules/adviceBuilder/insuranceResearch.js')
  resolveStoreModules(require('../store/modules/adviceBuilder/modelling.js'), 'modules/adviceBuilder/modelling.js')
  resolveStoreModules(require('../store/modules/adviceBuilder/platformResearch.js'), 'modules/adviceBuilder/platformResearch.js')
  resolveStoreModules(require('../store/modules/common/crudStore.js'), 'modules/common/crudStore.js')
  resolveStoreModules(require('../store/modules/common/filterStore.js'), 'modules/common/filterStore.js')
  resolveStoreModules(require('../store/modules/common/stickyNoteStore.js'), 'modules/common/stickyNoteStore.js')
  resolveStoreModules(require('../store/modules/documentEngine/documentBuilder.js'), 'modules/documentEngine/documentBuilder.js')
  resolveStoreModules(require('../store/modules/documentEngine/elementBuilder.js'), 'modules/documentEngine/elementBuilder.js')
  resolveStoreModules(require('../store/modules/practiceManager/dataFeedManager.js'), 'modules/practiceManager/dataFeedManager.js')
  resolveStoreModules(require('../store/modules/practiceManager/pipeline.js'), 'modules/practiceManager/pipeline.js')
  resolveStoreModules(require('../store/modules/practiceManager/providers.js'), 'modules/practiceManager/providers.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
