
import storeMixin from '@/mixins/storeMixin'
import configMixin from '~/mixins/configMixin.js'
import statusMixin from '~/mixins/statusMixin.js'

export default {
  mixins: [storeMixin, configMixin,statusMixin],
  props: {
    headerBar: {
      type: Boolean,
      default: false,
    },
    basic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      recent: [],
      isFocused: false,
      btnSearchFocused: false,
      btnMenuOpen: false,
      filterMenu: false,
      overflowLabels: [],
      localSelectedFilters: [],
    }
  },
  computed: {
    selectedFilters: {
      get() {
        return this.localSelectedFilters
      },
      set(val) {
        this.localSelectedFilters = val
        localStorage.setItem('clientGroupFilters', JSON.stringify(val))
      },
    },
    filterOptions() {
      return [
        {
          text: 'Client Group Status',
          value: 'status',
          options: [],
        },
        {
          text: 'Team',
          value: 'team',
          options: this.teams.map((el) => {
            return {
              text: el.name,
              value: el.id,
            }
          }),
        },
        {
          text: 'Segment',
          value: 'segment',
          options: this.segments.map((el) => {
            return {
              text: el.name,
              value: el.id,
            }
          }),
        },
      ]
    },
    segments() {
      return this.getConfigItems('clientGroup.segments')
    },
    teams() {
      return this.getConfigItems('organisation.teams')
    },
    allGroups() {
      let groups = this.$store.getters[`modules/entityGroups/getItems`]
      if (!groups) return []
      return groups
        .filter((el) => !this.recentSearches.find((recent) => recent.id === el.id))
        .map((el) => {
          return { id: el.id, name: el?.summary?.name }
        })
    },
    isEditMode() {
      const module = this.$route?.name?.split('-')[0]
      return module ? this.$store.state?.modules[module]?.editMode : false
    },
    disableNav() {
      const inEdit = this.$db.isAppInEdit(this.$utils.getRouteParams(this.$route.path)[0], this.$utils.getRouteParams(this.$route.path)[1])
      return this.isEditMode || inEdit
    },
    recentSearches: {
      get() {
        // Use lodash filter to find matching objects based on IDs from the recent
        const recentGroups = _.filter(this.$store.state.modules.entityGroups.allItems, (group) => this.recent.includes(group.id) && !_.has(group, 'deleted'))
        if (Array.isArray(recentGroups) && recentGroups.length > 0) {
          return this.recent
            .map((id) => {
              const el = _.find(recentGroups, { id })
              if (!el) return null
              return { id: el.id, name: el.summary.name }
            })
            .filter((el) => !!el)
        }
        return []
      },
      set(searches) {
        // Set searches locally and in local storage to keep it consistent and for reactivity
        this.recent = searches
        localStorage.setItem('recentSearches', JSON.stringify(searches))
      },
    },
    selectedGroup: {
      get() {
        return this.$store.state.modules.entityGroups.selectedEntityGroup
      },
      set(groupId) {
        this.addToRecent(groupId)
        this.$store.dispatch(`modules/entityGroups/selectEntityGroup`, { id: groupId })
        this.filterMenu = false
      },
    },
    hasSearchInput() {
      if (this.search && this.search.trim() !== '') return true
      return false
    },
    menuOpen() {
      return this.$store.state.application.appData.showContextMenu
    },
    groups() {
      return this.$store.state.modules.entityGroups.allItems.map((group) => {
        return { id: group.id, name: group.summary.name, deleted: group.deleted }
      })
    },
    items() {
      // Show recent searches if no filter or search criteria
      if (!this.hasSearchInput) return _.take(this.recentSearches, 10)

      // Filter
      let filtered = this.groups.filter((el) => {
        if (el.deleted) return false // Filter out deleted
        if (this.selectedFilters.length > 0) {
          let groupedFilters = _.groupBy(this.selectedFilters, 'group')
          Object.keys(groupedFilters).forEach((key) => {
            groupedFilters[key] = groupedFilters[key].map((el) => el.value)
          })

          const entityGroup = this.$store.getters[`modules/entityGroups/getObject`](el?.id)
          if (!entityGroup) return false
          // Conditional OR for each group (e.g. Team 1 || Team 2)
          const validStatus = !Array.isArray(groupedFilters.status) || groupedFilters.status.includes(entityGroup.status)
          const validSegment = !Array.isArray(groupedFilters.segment) || groupedFilters.segment.includes(entityGroup.summary?.segment)
          const validTeams = !Array.isArray(groupedFilters.team) || groupedFilters.team.some((id) => entityGroup.adviceTeam?.teams.includes(id))
          // Conditional AND if multiple groups (e.g. Team 1 || Team 2 && Active)
          return validStatus && validSegment && validTeams
        }
        return true
      })

      // Search
      if (this.search) filtered = filtered.filter((el) => el.name.toLowerCase().includes(this.search.trim().toLowerCase()))
      return filtered
    },
    disableNav() {
      const isEdit = _.get(this.$store.state, `modules.${this.selectedModule}.editMode`, false)
      const autosave = _.get(this.$store.getters['modules/configurationHub/getCurrentUserSettings'], 'autoSave', false)
      if (autosave) return false
      return isEdit
    },
  },
  methods: {
    filterClick(e, click) {
      // Open/close filter menu based on automcomplete menu
      const autocompleteMenu = this.$refs.search?.isMenuActive
      if (autocompleteMenu && !this.filterMenu) {
        click(e)
      } else if (!autocompleteMenu) {
        this.filterMenu = false
      }
    },
    isTextOverflow(event, id) {
      const { target } = event
      const isOverflow = target.offsetWidth < target.scrollWidth
      // Add to array of tooltips needing to be displayed
      if (isOverflow && !this.overflowLabels.includes(id)) this.overflowLabels.push(id)
      return isOverflow
    },
    getItemDescription(item) {
      const group = this.$store.getters[`modules/entityGroups/getObject`](item?.id)
      const segment = this.segments.find(({ id }) => id === group.summary?.segment)?.name
      const teams = this.teams.filter(({ id }) => group.adviceTeam.teams.includes(id)).map((el) => el.name)
      return this.$utils.concatStrings(', ', [segment, ...teams])
    },
    openAdvancedSearch() {
      // Open giga search
      let props = {
        select: true,
        returnObject: true,
        multiple: false,
        callback: this.selectFromAdvanced,
        modules: ['entityGroups', 'entities'],
      }
      this.$store.commit(`application/globalSearch/openDialog`, props)
    },
    selectFromAdvanced(obj) {
      if (!obj) this.selectedGroup = null
      if (obj.id.includes('entities')) {
        // Group ID from entity
        this.selectedGroup = obj.entityGroupId
      } else {
        // Is already group
        this.selectedGroup = obj.id
      }
    },
    highlightMatch(item) {
      let groupName = item?.name
      let groupDescription = this.getItemDescription(item)
      const length = (item?.name + groupDescription).length
      if (this.search) {
        // Highlight matched text
        const regex = new RegExp(this.search, 'gi')
        groupName = groupName.replace(regex, '<span style="color: var(--v-success-lighten3)">$&</span>')
        groupDescription = groupDescription.replace(regex, '<span style="color: var(--v-success-lighten3)">$&</span>')
      }
      groupName = `<span style="color: white">${groupName}</span>`
      groupDescription = `<span style="font-style: italic; color: white; opacity: 0.7; padding-left: 12px; padding-right: 4px">${groupDescription}</span>`
      return { text: `${groupName}${groupDescription}`, length: length }
    },
    clearSearch(menuOpen) {
      if (!menuOpen) this.search = ''
    },
    addToRecent(groupId) {
      if (groupId && Array.isArray(this.recent)) {
        // Add group to recent searches if it's not in it already
        if (!this.recent.includes(groupId)) {
          this.recent.unshift(groupId)
          // limit to 10 recents
          if (this.recent.length > 10) this.recent.pop()
          this.recentSearches = this.recent
        }
      }
    },
    removeFromRecent(groupId) {
      if (groupId) {
        _.remove(this.recent, (el) => el === groupId)
      }
    },
    openGroupInTab(group) {
      this.addToRecent(group.id)
      // Open group in a new tab with ID as the query
      let routeData = this.$router.resolve({ path: this.$route.params.module ? `/${this.$store.getters['application/appData/getAppValueFromModule'](this.$route.params.module)}` : '', query: { group: group.id } })
      window.open(routeData.href, '_blank')
    },
    openSearchMenu() {
      if (this.$refs.search) {
        this.$refs.search.focus()
        this.$refs.search.activateMenu()
      }
    },
    openNewDialog(openTab) {
      this.$store.commit(`modules/entityGroups/toggleCreateDialog`, { show: true, openTab: openTab })
    },
    getRecentSearches() {
      // Retrieve localstorage data
      let recent = []
      try {
        recent = JSON.parse(localStorage.getItem('recentSearches'))
        if (!Array.isArray(recent)) recent = []
        // Filter recent searches only show existing groups
        recent = recent.filter((id) => this.$store.state.modules.entityGroups.allItems.findIndex((group) => group.id === id) >= 0)
      } catch (e) {
        console.warn('Could not parse local JSON data', e)
        recent = []
      }
      return recent
    },
    getStoredFilters() {
      // Retrieve localstorage data
      let filters = []
      try {
        filters = JSON.parse(localStorage.getItem('clientGroupFilters'))
        if (!Array.isArray(filters)) {
          // Default to empty array
          this.selectedFilters = []
          return
        }
      } catch (e) {
        console.warn('Could not parse local JSON data', e)
        filters = []
      }
      this.localSelectedFilters = filters
    },
    clearRecent() {
      if (this.$refs.search) {
        this.$refs.search.blur()
      }
      this.recentSearches = []
    },
  },
  mounted() {
    // Setup from localstorage data
    this.recentSearches = _.take(this.getRecentSearches(), 10)
    this.getStoredFilters()

    // Event to open group search from elsewhere
    if (this.headerBar) {
      this.$nextTick(() => {
        this.$bus.$off('openGroupSearch')
        this.$bus.$on('openGroupSearch', this.openSearchMenu)
      })
    }

    // Event handler for adding a recent search from elsewhere in the app
    this.$bus.$on('addRecentSearch', this.addToRecent)
    this.$bus.$on('removeRecentSearch', this.removeFromRecent)
  },
}
