import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('activities')

export const state = () => ({
  ...crud.state,
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters) => (id) => {
    const defaultName = 'Activity'
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return defaultName
    return obj?.subject
  },
}

export const mutations = {
  ...crud.mutations,
}

export const actions = {
  ...crud.actions,
  // Start and End need to be moment date objects
  async findRange({ commit }, body) {
    if (!body.start) return []
    return await this.$axios.$post('/api/v1/activities/findRange', body).then((res) => {
      if (!res) return []
      commit('mergeItems', res)
      return res
    })
  },
}
