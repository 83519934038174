
export default {
  data() {
    return {
      search: '',
    }
  },
  props: {
    /**
     * Selection array, will populate with objects { group: group.value, option: option.value }
     * e.g. [{ group: 'status', option: 'ACTIVE' }]
     */
    value: {
      type: Array,
      default: null,
    },
    /**
     * Boolean to control the menu if 'v-on' isn't used from the slot
     */
    menu: {
      type: Boolean,
      default: undefined,
    },
    /**
     * Array[] {
     *  text: "Group One",
     *  value: "group1",
     *  options: [
     *    {
     *      text: "Filter Option One",
     *      value: "filterOption1"
     *    }
     *  ]
     * }
     */
    filterItems: {
      type: Array,
      default: null,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    /**
     * Removes maximum height from the component content
     */
    fillHeight: {
      type: Boolean,
      default: false,
    },
    /**
     * Maximum options to display before collapsing into a dropdown
     */
    maxDisplay: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    filteredFilterItems() {
      if (!this.search || this.search.length < 2) return this.filterItems
      const _search = this.search.toLowerCase()
      return this.filterItems
        .filter((group) => {
          // Group result
          if (group.text.toLowerCase().includes(_search)) return true

          // Group options result
          const options = group.options.map((option) => this.maskText(option.text, group.mask).toLowerCase())
          const optionsIncludesSearchText = _.intersectionWith(options, [_search], (val, other) => val.includes(other))
          return optionsIncludesSearchText.length !== 0
        })
        .map((group) => {
          // Group options result
          const options = group.options.map((option) => this.maskText(option.text, group.mask).toLowerCase())
          const optionsIncludesSearchText = _.intersectionWith(options, [_search], (val, other) => val.includes(other))

          if (optionsIncludesSearchText.length !== 0) {
            const matches = group.options.filter((el) => this.maskText(_.get(el, 'text', ''), group.mask).toLowerCase().includes(_search))
            const nonMatches = _.xor(matches, group.options)

            return {
              ...group,
              // Set the max to the number of matches. The non-matches will be contained in the dropdown
              max: matches.length,
              options: _search ? matches : [...matches, ...nonMatches],
            }
          } else return group
        })
    },
    filterName() {
      return this.filterItems
        .map(({ group, value }) => {
          const options = this.filterItems.find((item) => item.value === group)?.options
          return options.find((el) => el.value === value)?.text
        })
        .join(', ')
    },
  },
  methods: {
    maskText(text, mask) {
      // TODO: pass a masking function into the god table and filter menu, because this is cooked
      switch (mask) {
        case 'status':
        case 'enums':
          return this.$utils.capitaliseWords(text)
        case 'users':
          if (!String(text).startsWith('auth0|')) return text
          return this.$store.getters['auxiliary/user/getUsernameForId'](text)
        case 'entities':
          const entityName = this.$utils.getDisplayName(text)
          return entityName != '' ? entityName : 'Not Found'
        case 'entityGroupId':
          return this.$store.getters['modules/entityGroups/getGroupName'](text)
        case 'taskTags':
        case 'tags':
          const workflowTag = this.$store.getters[`modules/configurationHub/getTag`](text)
          return workflowTag ? ' ' + workflowTag.name : 'No Tags'
        default:
          return text
      }
    },
    // Toggles the whole group
    activateFilterGroup(group) {
      const menuOptions = _.slice(group.options, this.maxDisplay, group.options.length)
      const options = menuOptions.map((option) => this.createSelectionObject(option, group))
      const selectionStatus = this.getGroupStatus(group)
      switch (selectionStatus) {
        case 'full':
          this.$emit(
            'input',
            this.value.filter((el) => !options.find((option) => _.isEqual(el, option)))
          )
          break
        case 'half':
        case 'empty':
        default:
          this.$emit('input', _.unionWith(this.value, options, _.isEqual))
          break
      }
    },
    getFiltersForGroup(group) {
      //Get the dropdown options only
      let options = _.slice(group.options, this.maxDisplay, group.options.length)
      options = options.filter((option) => this.isFilterActive(option, group))
      return options.map((el) => el.value)
    },
    isFilterActive(option, group) {
      const groupOption = this.createSelectionObject(option, group)
      return !!this.value.find((el) => _.isEqual(el, groupOption))
    },
    getGroupStatus(group) {
      let localCopy = _.cloneDeep(group)
      let options = _.slice(localCopy.options, this.maxDisplay, localCopy.options.length)

      const allValuesExist = _.every(options, (option) => this.isFilterActive(option, group))
      const someValuesExist = _.some(options, (option) => this.isFilterActive(option, group))
      const noneValuesExist = _.every(options, (option) => !this.isFilterActive(option, group))

      if (options.length > 0 && noneValuesExist) return 'empty'
      else if (localCopy.options.length > 0 && allValuesExist) return 'full'
      else if (someValuesExist) return 'half'
    },
    addFilter(option, group) {
      let clone = _.cloneDeep(this.value)
      const selection = this.createSelectionObject(option, group)
      const foundIndex = clone.findIndex((el) => _.isEqual(el, selection))
      if (foundIndex >= 0) clone.splice(foundIndex, 1)
      else clone.push(selection)
      this.$emit('input', clone)
    },
    getFilterObject(option, group) {
      return { value: option.value, group: group.value }
    },
    createSelectionObject(option, group) {
      return {
        group: group.value,
        value: option.value,
      }
    },
  },
}
