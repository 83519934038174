import _ from 'lodash'

function retrieveLocalFilterStore(path, defaultValue = null) {
  let item = localStorage.getItem('filters')
  if (item != null) item = JSON.parse(item)
  else item = {}
  return _.get(item, path, defaultValue)
}

function setLocalFilterStore(path, value) {
  // detect filter cleared
  if (_.get(value, 'segment', null) == null && _.get(value, 'filter.filterOptions.length', 0) == 0) value = null

  let item = localStorage.getItem('filters')
  if (item != null) item = JSON.parse(item)
  else item = {}

  // set, or delete if filter cleared
  if (value == null) {
    delete item[path]
  } else {
    _.set(item, path, value)
  }
  localStorage.setItem('filters', JSON.stringify(item))
}

export const state = () => ({
  filters: { pages: [] },
  filtersPending: false,
  activeFilters: null,
})

export const getters = {
  // Get the filters for the table by page
  getByPage: (state) => (_page) => {
    //TODO Rework this?

    const pages = _.get(state, 'filters.pages', null)
    if (!Array.isArray(pages)) return null

    const page = _.cloneDeep(pages.find((pg) => pg.page === _page))
    if (!page) return null

    // Filter out DELETED
    if (page.filters && Array.isArray(page.filters)) {
      page.filters = page.filters.filter((pg) => !pg.deleted)
    }

    if (pages) return page
    return null
  },
  // Get the filters for the PAGE by the route params
  getByRoute: (state, getters) => {
    const page = getters.getFilterPage
    return getters.getByPage(page)
  },
  // Returns the "table Id" - i,e. where to apply the saved filters
  getFilterPage: (state) => {
    return $nuxt?.$route?.name
  },
  addFilterToPage: (state) => (page, filterOptions) => {
    const pages = _.cloneDeep(_.get(state.filters, 'pages', [])).find((pg) => pg.page === page)
    const newPages = _.cloneDeep(_.get(state.filters, 'pages', []))

    // Add the new filter
    if (!pages) newPages.push({ page, filters: [filterOptions] })
    else newPages.find((pg) => pg.page === page).filters.push(filterOptions)

    return newPages
  },
  createFilterObject: (state) => (filterOptions, segment = '', filterName = '', filterId = '') => {
    return { filterName, filterId, segment, filterOptions }
  },
  getActiveFilterByPage: (state) => (page) => {
    return _.get(state.activeFilters, page)
  },
  isFavourited: (state) => (id) => {
    const flat = state.filters.pages.flatMap((el) => el.favourites)
    return flat.includes(id)
  },
}

export const mutations = {
  setPending(state, payload) {
    state.filtersPending = payload
  },
  setFilters(state, payload) {
    state.filters = payload
  },
  setActiveFilter(state, { page, filter, segment }) {
    const activeFilters = _.set(_.cloneDeep(state.activeFilters) || {}, page, { filter: filter, segment: segment })
    state.activeFilters = activeFilters

    if (_.get($nuxt.$route, 'meta.filters.localStorage', false)) {
      setLocalFilterStore($nuxt.$route.path, _.get(activeFilters, page, {}))
    }
  },
  loadActiveFilterFromLocalStorage(state, { page }) {
    if (!_.get($nuxt.$route, 'meta.filters.localStorage', false)) return
    const localStored = retrieveLocalFilterStore($nuxt.$route.path)
    const activeFilters = _.set(_.cloneDeep(state.activeFilters), page, localStored)
    state.activeFilters = activeFilters
  },
  currentPage(state, page) {
    state.currentPage = page
  },
}

export const actions = {
  /**
   * Get the saved filters on app load
   */
  async getUserFilters({ state, commit }) {
    if (state.filters.pages.length != 0) return

    commit('setPending', true)
    await this.$axios.get('/api/v1/userFilter').then((res) => {
      if (!res?.data?.pages) return
      commit('setFilters', _.cloneDeep(res.data))
    })

    commit('setPending', false)
  },
  /**
   * Updates the user's filter object, saving the new filter entry
   */
  async saveUserFilter({ state, commit, getters }, { filters, page, segment, filterName }) {
    filters = _.flatMap(filters, (item) => {
      if (Array.isArray(item.value)) {
        return item.value.map((flatValue) => ({
          group: item.group,
          value: flatValue,
        }))
      } else {
        return item
      }
    })
    let pages = getters.addFilterToPage(page, getters.createFilterObject(filters, segment, filterName, this.$uuid()))
    if (!pages) return
    commit('setPending', true)
    commit('setFilters', await this.$axios.$put('/api/v1/userFilter', { pages }))
    commit('setPending', false)
    return true
  },
  // Do a look through all filters in the store, looking for the filter id
  // Then set the optional key-value pairs (rest) to override the existing key-values in the found filter object
  async updateFilter({ state, commit }, { filterId, ...rest }) {
    if (Object.keys(rest).length === 0) return

    // Get page id ( app_module_card_tableId ) via filterId, and
    const newPages = _.get(state, 'filters.pages', []).map((page) => {
      return {
        ...page,
        filters: (page.filters || []).map((filter) => {
          return filter.filterId === filterId ? { ...filter, ...rest } : { ...filter }
        }),
      }
    })

    commit('setPending', true)

    // Send off request
    commit('setFilters', await this.$axios.$put('/api/v1/userFilter', { pages: newPages }))
    commit('setPending', false)
  },
  async toggleFavourite({ state, commit, dispatch }, { page, id, remove }) {
    commit('currentPage', page)
    let filterClone = _.cloneDeep(state.filters)
    //Do this so ui updates immediately, it will be overridded in ~500ms with the currect server state
    const pageIndex = _.findIndex(state.filters.pages, (el) => _.isEqual(el.page, state.currentPage))
    if (pageIndex === -1 && !remove) {
      //create a new one
      filterClone.pages.push({ page: state.currentPage, favourites: [id] })
    } else if (remove) {
      _.remove(filterClone.pages[pageIndex].favourites, (el) => el === id)
    } else {
      _.set(filterClone.pages, `[${pageIndex}].favourites`, _.xor(state.filters.pages[pageIndex].favourites, [id]))
    }
    commit('setFilters', filterClone)
    dispatch('debouncedUpdateFavourites')
  },

  debouncedUpdateFavourites: _.debounce(async function ({ commit, state }) {
    const pageIndex = _.findIndex(state.filters.pages, (el) => _.isEqual(el.page, state.currentPage))
    if (pageIndex === -1) return // Handle case where page is not found
    commit('setPending', true)
    commit('setFilters', await this.$axios.$put('/api/v1/userFilter', { pages: state.filters.pages }))
    commit('setPending', false)
  }, 1000),
  async removeFilter({ state, commit }, { page, filterId }) {
    // Remove the filter by the filterId from the page's filters (mark as DELETED)
    const pageIndex = _.findIndex(state.filters.pages, (el) => _.isEqual(el.page, page))
    const currentPage = _.get(state.filters, 'pages.' + pageIndex)
    const newPageFilters = currentPage.filters.map((el) => {
      if (el.filterId !== filterId) return el
      return { ...el, deleted: 'DELETED' }
    })

    const pages = _.set(_.cloneDeep(state.filters.pages), `[${pageIndex}].filters`, newPageFilters)

    commit('setPending', true)
    commit('setFilters', await this.$axios.$put('/api/v1/userFilter', { pages }))
    commit('setPending', false)
  },
  async restoreFilter({ state, commit }, { page, filterId }) {
    // Filters should never be deleted from a page, instead marked as deleted: 'DELETED'
    //  When retrieving filters, get the non-DELETED ones only
    const pageIndex = _.findIndex(state.filters.pages, (el) => _.isEqual(el.page, page))
    const currentPage = _.get(state.filters, 'pages.' + pageIndex)

    const newPageFilters = currentPage.filters.map((el) => {
      if (el.filterId !== filterId) return el
      // omit the 'deleted' key
      return { filterId: el.filterId, filterName: el.filterName, filterOptions: el.filterOptions, segment: el.segment }
    })

    const pages = _.cloneDeep(state.filters.pages).map((pg, i) => {
      if (i === pageIndex) {
        // Set newPageFilters
        return { ...pg, filters: newPageFilters }
      } else return pg
    })

    commit('setPending', true)
    commit('setFilters', await this.$axios.$put('/api/v1/userFilter', { pages }))

    commit('setPending', false)
  },
}
