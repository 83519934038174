import { render, staticRenderFns } from "./ApplicationHeaderBar.vue?vue&type=template&id=4491de40&scoped=true"
import script from "./ApplicationHeaderBar.vue?vue&type=script&lang=js"
export * from "./ApplicationHeaderBar.vue?vue&type=script&lang=js"
import style0 from "./ApplicationHeaderBar.vue?vue&type=style&index=0&id=4491de40&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4491de40",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ApplicationAppSelect: require('/usr/src/app/components/application/ApplicationAppSelect.vue').default,ApplicationContextMenu: require('/usr/src/app/components/application/menu/ApplicationContextMenu.vue').default,ApplicationContextSearchInput: require('/usr/src/app/components/application/input/ApplicationContextSearchInput.vue').default,ApplicationKnowledgeBaseMenu: require('/usr/src/app/components/application/menu/ApplicationKnowledgeBaseMenu.vue').default,ApplicationNotificationMenu: require('/usr/src/app/components/application/menu/ApplicationNotificationMenu.vue').default,ApplicationAccountMenu: require('/usr/src/app/components/application/menu/ApplicationAccountMenu.vue').default})
