import FilterStore from './common/filterStore'

const crud = new FilterStore('tasks', null, false, true)

export const state = () => ({
  ...crud.state,
  noSidebar: true,
  pendingScheduledData: null,
  pendingSubtaskSaves: [],
  pendingSubtaskDeletes: [],
  pendingParentTask: null,
  newTemplates: {
    task: null,
    template: null,
  },
  actionStatus: [],

  /**
   * @override
   */
  editMode: false,

  // map of { oldId: newId }
  // Because we don't know the subtaskId until response
  newSubtaskIds: {},
  topBarButtons: [
    { name: 'Tasks', link: '/tasks' },
    { name: 'Template Tasks', link: '/tasks/templates' },
  ],
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters) => (id) => {
    const defaultName = 'Unnamed'
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return defaultName
    return obj?.name
  },
  getItems(state, getters) {
    let items = getters['getAllItems'].filter((el) => !el.deleted || el.deleted === 'ARCHIVED')
    return items // DON'T Sort the items by default
  },
  getTasks(state, getters, rootState) {
    return getters['getAllItems'].filter((el) => (!el.deleted || el.deleted === 'ARCHIVED') && !el.isTemplate)
  },
  getTemplateTasks(state, getters, rootState) {
    return getters['getAllItems'].filter((el) => (!el.deleted || el.deleted === 'ARCHIVED') && el.isTemplate)
  },
  areFiltersActive: (state) => {
    return state.filters.task.assignees.length > 0 || state.filters.task.statuses.length > 0 || state.filters.task.segment != undefined
  },
  isSubtaskPending: (state) => (subtask) => {
    return _.cloneDeep(state.pendingSubtaskSaves).findIndex((el) => el.id === subtask.id) !== -1
  },
  getPendingSubtasks: (state) => (subtaskIds) => {
    if (!subtaskIds) return []
    if (typeof subtaskIds === 'string') subtaskIds = [subtaskIds]
    return state.pendingSubtaskSaves.filter((subtask) => subtaskIds.includes(subtask.id))
  },
}

export const mutations = {
  ...crud.mutations,
  setPendingParentTask: (state, task) => {
    state.pendingParentTask = task
  },
  setPendingScheduleData: (state, scheduleData) => {
    state.pendingScheduledData = scheduleData
  },
  updatePendingSubtask: (state, subtask) => {
    let foundIndex = state.pendingSubtaskSaves.findIndex((el) => el.id == subtask.id)
    state.pendingSubtaskSaves.splice(foundIndex, 1, subtask)
  },
  removePendingSubtask: (state, subtaskId) => {
    state.pendingSubtaskSaves = state.pendingSubtaskSaves.filter((sub) => sub.id != subtaskId)
  },
  addPendingSubtaskSaves: (state, subTask) => {
    if (Array.isArray(subTask)) state.pendingSubtaskSaves.push(...subTask)
    else {
      // try to splice into array first
      const idx = state.pendingSubtaskSaves.findIndex((el) => el.id === subTask.id)
      if (idx === -1) state.pendingSubtaskSaves.push(subTask)
      else state.pendingSubtaskSaves.splice(idx, 1, subTask)
    }
  },
  clearPendingSubtaskDeletes(state) {
    state.pendingSubtaskDeletes = []
  },
  addPendingSubtaskDeletes: (state, subTask) => {
    if (!Array.isArray(subTask)) subTask = [subTask]
    subTask = subTask.map((el) => (typeof el === 'string' ? el : el.id))

    state.pendingSubtaskDeletes = _.union(state.pendingSubtaskDeletes, subTask)
  },
  clearPending: (state) => {
    state.pendingSubtaskSaves = []
    state.pendingParentTask = null
    state.pendingScheduledData = null
  },
  addItems(state, items) {
    state.allItems = [...state.allItems, ...items]
  },
  addNewSubtaskId(state, { newId, oldId }) {
    state.newSubtaskIds[oldId] = newId
  },
}
export const actions = {
  ...crud.actions,
  getActionStatus: (state) => (id) => {
    // Statuses not tied to object for file notes because they just have active/inactive
    if (['fileNotes'].includes(id?.type)) {
      return id?.isComplete ? 'COMPLETED' : 'IN_PROGRESS'
    }
    if (typeof id === 'object' && _.has(id, 'lookupId') && !_.isNil(id.lookupId)) id = id?.lookupId
    else if (typeof id === 'object' && _.has(id, 'id')) id = id?.id
    return state.actionStatus.find((f) => f.id === id)?.status ?? 'NOT_STARTED'
  },
  /**
   * @override
   */
  async patchStatus({ commit }, { id, status }) {
    if (!status) return
    commit('updateField', { id: id, field: 'status', value: status })
    await this.$axios.patch(`/api/v1/tasks/status/${id}`, null, {
      params: {
        status: status,
      },
    })
  },
  async getTasksByIds({ commit }, ids) {
    let tasks = await this.$axios.$get(`/api/v1/tasks?ids=${ids}`)
    commit('mergeItems', tasks)
    return tasks
  },
  async saveNewTask({ state, dispatch, commit }, task) {
    let taskClone = _.cloneDeep(task.type === 'SUBTASK' ? state.pendingParentTask : task)
    taskClone.dateCreated = new Date()
    if (taskClone.workflowSubtasks?.length > 0) {
      // Setup workflow subtasks to be saved
      // commit('addPendingSubtaskSaves', taskClone.workflowSubtasks)
      taskClone.subtaskIds = taskClone.workflowSubtasks.map((el) => el.id)
      taskClone.workflowSubtasks = []
    }

    const [subtaskIds] = await dispatch('savePendingSubtasks', taskClone.subtaskIds)
    taskClone.subtaskIds = subtaskIds
    taskClone.dueDate = taskClone.dueDate == null || taskClone.dueDate == 'Invalid date' || taskClone.dueDate == '' ? null : $nuxt.$utils.formatDateForSever(taskClone.dueDate)
    const newTask = _.cloneDeep(await this.$axios.$post(`/api/v1/tasks`, taskClone))
    commit('addObject', newTask)
    // commit('setPendingParentTask', null)

    return newTask
  },
  async updateTask({ commit, dispatch, state }, task) {
    const [newIds, oldIds] = await dispatch('savePendingSubtasks', task.subtaskIds)
    const deleted = await dispatch('deletePendingSubtasks')

    // when updating, ensure to filter out old or deleted ids
    let _task = _.cloneDeep(task)
    _task.dueDate = _task.dueDate == null || _task.dueDate == 'Invalid date' || _task.dueDate == '' ? null : $nuxt.$utils.formatDateForSever(_task.dueDate)
    _task.subtaskIds = !_.isNil(task.subtaskIds) ? task.subtaskIds.filter((el) => !deleted.includes(el) && !oldIds.includes(el)).concat(newIds) : []

    commit('updateObject', _task)
    // commit('application/storeUtils/modifyCollection', { collection: state.allItems, item: _task, action: 'update' }, { root: true })
    _task = await this.$axios.$put(`/api/v1/tasks/${task.id}`, _task)
    return _task
  },
  /**
   * Saves each pending subtask then clears the pending saves array
   * @param {*} param0
   * @param {*} subtaskIds
   * @returns `Array<Array<String>>` 0: new subtask ids, 1: old subtask ids
   */
  async savePendingSubtasks({ getters, commit }, subtaskIds) {
    let subtaskPromises = []
    const pending = getters.getPendingSubtasks(subtaskIds)

    pending.forEach((subTask) => {
      let clone = _.cloneDeep(subTask)
      clone.dueDate = _.isNil(clone.dueDate) ? null : $nuxt.$utils.formatDateForSever(clone.dueDate)
      subtaskPromises.push(this.$axios.post(`/api/v1/tasks/`, clone))
    })
    // Pending subtasks can be created when creating a fresh task
    const result = await Promise.all(subtaskPromises)
    const newSubtasks = result.map((el) => el?.data)
    commit('mergeItems', newSubtasks)

    for (let i = 0; i < newSubtasks.length; i++) {
      commit('addNewSubtaskId', { newId: newSubtasks[i].id, oldId: pending[i].id })
    }

    commit('clearPending')

    return [newSubtasks.map((el) => el.id), pending.map((el) => el.id)]
  },
  async deletePendingSubtasks({ state, dispatch, commit }) {
    const deleted = _.clone(state.pendingSubtaskDeletes)
    await dispatch('delete', state.pendingSubtaskDeletes)
    commit('clearPendingSubtaskDeletes')

    return deleted
  },
  async delete({ state, dispatch, commit, getters, rootState }, ids) {
    if (!Array.isArray(ids)) ids = [ids]
    if (ids.length === 0) return

    // Remove objects from state
    try {
      const tasks = getters['getTasks'].filter((el) => el.type === 'TASK')
      ids.forEach((id) => {
        const found = tasks.find((task) => _.intersection([id], task.subtaskIds).length > 0)
        if (found) {
          // Remove subtask from parent task
          let clone = _.cloneDeep(found)
          _.remove(clone.subtaskIds, (el) => el === id)
          dispatch('update', { payload: clone })
        }
      })
      commit('removeObjects', ids)
      return await this.$axios.delete(`/api/v1/tasks?ids=${ids.join(',')}`)
    } catch (e) {
      return e
    }
  },
  async onStompUpdate({ getters, commit }, stompProps) {
    const routeName = this.$router?.currentRoute?.name
    const data = stompProps?.data
    if (!data) return
    // If on tasks just update comments to avoid messing with the displayed tasks in the table
    if (routeName && routeName.includes('tasks')) {
      commit('updateField', { id: data.id, field: 'comments', value: data?.comments })
      this.$bus.$emit('comments-update', { id: data.id, comments: data.comments })
    } else {
      // Any other route update the whole object
      commit('updateObject', data)
    }
    // const stompId = _.get(stompProps, 'stompMessage.ids[0]')
    // // Only fetch if it's in the store already meaning it's being used somewhere
    // if (!getters['getTasks'].find((el) => el.id === stompId)) return
    // let task = await this.$axios.$get(`/api/v1/tasks/${stompId}`)
    // if (!task) return
    // const routeName = this.$router?.currentRoute?.name
    // // If on tasks just update comments to avoid messing with the displayed tasks in the table
    // if (routeName && routeName.includes('tasks')) {
    //   commit('updateField', { id: stompId, field: 'comments', value: task?.comments })
    //   this.$bus.$emit('comments-update', { id: stompId, comments: task.comments })
    // } else {
    //   // Any other route update the whole object
    //   commit('updateObject', task)
    // }
  },
  onStompAdd({}) {
    // Do nothing on stomp add event
  },
}
