import FilterStore from './common/filterStore'

const crud = new FilterStore('kanbans', null, false, true)

export const state = () => ({
  ...crud.state,
  noSidebar: false,
  topBarButtons: [
    { name: 'Kanbans', link: '/kanbans' },
    { name: 'Template Kanbans', link: '/kanbans/templates' },
  ],
  allTemplates: [],
  savingIds: [],
  favourites: [],
})

export const getters = {
  ...crud.getters,
  isSaving: (state) => (id) => {
    return state.savingIds.includes(id)
  },
  getAllTemplates: (state) => {
    return state.allTemplates.filter((el) => !el.deleted || el.deleted !== 'DELETED')
  },
  getItems(state, getters, rootState) {
    let items = getters['getAllItems'].filter((el) => !el.deleted || el.deleted === 'ARCHIVED')
    return items // DON'T Sort the items by default
  },
  getFavourite: (state) => (id) => {
    return state.favourites.find((fav) => fav.id === id)
  },
}

export const mutations = {
  ...crud.mutations,
  toggleSavingId: (state, nodeId) => {
    state.savingIds = _.xor(state.savingIds, [nodeId])
  },
  removeTemplate: (state, id) => {
    const templateIdx = state.allTemplates.findIndex((tmp) => tmp.id == id)
    if (templateIdx == -1) return
    state.allTemplates.splice(templateIdx, 1)
  },
  updateTemplate: (state, payload) => {
    const templateIdx = state.allTemplates.findIndex((tmp) => tmp.id == payload.id)
    if (templateIdx == -1) return
    state.allTemplates.splice(templateIdx, 1, payload)
  },
  addTemplate: (state, payload) => {
    const templateIdx = state.allTemplates.findIndex((tmp) => tmp.id == payload.id)
    if (templateIdx != -1) return
    state.allTemplates.push(payload)
  },
}

export const actions = {
  ...crud.actions,
  /**
   * @override
   */
  async delete({ state, commit, dispatch }, ids) {
    if (!Array.isArray(ids)) ids = [ids]
    if (ids.length === 0) return

    // Remove objects from state
    try {
      // Remove links
      const result = await this.$axios.delete(`/api/v1/${state.endpoint}?ids=${ids.join(',')}`)

      if (result?.status !== 200) return result

      if (this._actions[`modules/${state.endpoint}/removeLinks`]) dispatch(`removeLinks`, _.cloneDeep(ids))
      commit('removeObjects', ids)
      for (const id of ids) {
        commit('removeTemplate', id)
      }

      return result
    } catch (e) {
      // Delete failed add it back in?
      // commit('addObject', newObject)
      return e
    }
  },
  async getAllTemplates({ state, commit, rootGetters, getters }) {
    commit('application/loading/setLoading', true, { root: true })
    commit('application/storeUtils/setItem', { localState: state, item: 'allTemplates', value: await this.$axios.$get(`/api/v1/kanbans/templates`) }, { root: true })
    commit('application/loading/setLoading', false, { root: true })
    return getters.getAllTemplates
  },
  async createNewTemplate({ state, commit, dispatch }) {
    commit('application/loading/setLoading', true, { root: true })
    const newKanban = await this.$axios.$post(`/api/v1/kanbans/template`)
    dispatch('getAllTemplates', true)
    commit('application/loading/setLoading', false, { root: true })
    return newKanban
  },
  patchStatus({ commit }, { id, status }) {
    if (!status) return
    commit('updateField', { id: id, field: 'status', value: status })
    this.$axios.patch(`/api/v1/kanbans/status/${id}`, null, {
      params: {
        status: status,
      },
    })
  },
  async getFavs({ state, commit }, ids) {
    if (!ids || ids.length === 0) return
    const idsQuery = ids.join('&ids=')
    const value = await this.$axios.$get(`/api/v1/kanbans?ids=${idsQuery}`)
    if (!Array.isArray(value)) return
    
    const nonDeletedItems = value.filter(kanban => !('deleted' in kanban))
    commit('mergeItems', nonDeletedItems) // also merge with state.allItems
    commit('application/storeUtils/setItem', { localState: state, item: 'favourites', value: nonDeletedItems }, { root: true }) 
  },
  debounceSaveKanban: _.debounce(async function ({ state, dispatch, commit }, kanban) {
    commit('toggleSavingId', kanban.id)
    await dispatch('update', { payload: kanban })
    commit('toggleSavingId', kanban.id)

    if (kanban.isTemplate) {
      // commit to store
      commit('updateTemplate', kanban)
    }
    if (state.favourites.some((fav) => fav.id === kanban.id)) {
      await dispatch('getFavs', state.favoriteIds)
    }
  }, 1000),
  async onStompUpdate({ getters, commit, dispatch }, stompProps) {
    const data = stompProps?.data
    if (!data) return
    commit('updateObject', data)
    this.$bus.$emit('kanban-stomp-update', data)

    let fetchMap = _.groupBy(
      data.stages.flatMap((el) => el.lookupIds),
      (el) => this.$utils.storeFromId(el)
    )
    for (const [store, ids] of Object.entries(fetchMap)) {
      if (Array.isArray(ids)) await dispatch(`modules/${store}/getAll`, ids, { root: true })
    }

  },
  onStompAdd({ getters, commit, dispatch }, stompProps) {
    // Do nothing on stomp add event
  },
}
